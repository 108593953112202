import Swiper from 'swiper/swiper-bundle.min';
import anime from 'animejs';

export function index(){
	const indexbnr = document.getElementById('indexbnr');
	if(indexbnr){
		new Swiper(indexbnr, {
			slidesPerView: 'auto',
			centeredSlides: true,
			loop: true,
			spaceBetween: 0,
		});
	}
};